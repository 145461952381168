<template>
  <div>
    <div v-if="Matter" class="title-presentation">
      <div class="school-title w-50">
        {{ $getVisibleNames("mesh.faculty", false, "Facultad").toUpperCase() }}:
        {{ faculty ? faculty.name.toUpperCase() : "" }}
      </div>
      <div class="program-title w-50">
        {{ $getVisibleNames("mesh.career", false, "Programa").toUpperCase() }}:
        {{ careerFilter ? careerFilter.name.toUpperCase() : "" }}
      </div>
    </div>
    <div class="table-container">
      <!-- Fila 1 -->
      <!-- Nombre -->
      <div class="table-cell d-flex">
        <div class="primary-cell w-50">NOMBRE</div>
        <div class="secundary-cell name-matter w-50">
          <span>{{ oldMatter.name.toUpperCase() }}</span>
        </div>
      </div>
      <!-- Codigo -->
      <div class="table-cell d-flex">
        <div class="primary-cell w-50">
          {{
            $getVisibleNames(
              "manual.matter_code",
              false,
              "Código"
            ).toUpperCase()
          }}
        </div>
        <div class="secundary-cell code-matter w-50">
          <span>{{ oldMatter.code.toUpperCase() }}</span>
        </div>
      </div>
      <!-- Nivel del Plan -->
      <div v-if="Matter" class="table-cell d-flex">
        <div class="primary-cell w-50">NIVEL DEL PLAN</div>
        <div class="secundary-cell w-50">
          <span>{{ Matter.plan_level }}</span>
        </div>
      </div>

      <!-- Fila 2 -->

      <!-- Prerequisitos -->
      <div v-if="Matter" class="table-cell d-flex">
        <div class="primary-cell w-50">PRERREQUISITO(S)</div>
        <div class="secundary-cell w-50">
          <p v-if="prerequisites.length == 0" class="prerequisites-p">
            No tiene
          </p>
          <div
            v-else
            :id="`prerequisite-label-${Matter.id}`"
            class="campus-container"
          >
            <span
              v-for="(prerequisite, index) in prerequisites"
              :key="'prerequisites' + index"
              class="campus-div noprint"
              :style="{
                'background-color': matterFormationLine,
                color: $lightOrDark(matterFormationLine) ? '#2b2a2a' : 'white',
              }"
            >
              <span class="prerequisite-order">
                {{ prerequisite.order }}
              </span>
            </span>
            <span class="prerequisite-code">
              {{ prerequisites.map((x) => x.code).join(", ") }}
            </span>
            <PrerequisitesPopover
              v-if="prerequisites.length > 0"
              :object="Matter"
              :content="prerequisitesNames"
              :placement="'top'"
              :triggers="'hover'"
              :title="'Requisitos'"
            >
            </PrerequisitesPopover>
          </div>
        </div>
      </div>

      <!-- Semanas -->
      <div class="table-cell d-flex">
        <div class="primary-cell w-50">SEMANAS</div>
        <div class="secundary-cell w-50">
          <div v-if="Matter && !Matter.use_matter_values">
            <span>{{ Matter.weeks }}</span>
          </div>
          <div v-else>
            <span>{{ oldMatter.weeks }}</span>
          </div>
        </div>
      </div>
      <!-- Perfil de Egreso -->
      <div v-if="Matter" class="table-cell d-flex">
        <div class="primary-cell w-50">
          {{
            $getVisibleNames(
              "mesh.egressprofile",
              false,
              "Perfil de Egreso"
            ).toUpperCase()
          }}
        </div>
        <div class="secundary-cell w-50">
          <span v-if="getEgressProfile">{{ getEgressProfile }}</span>
        </div>
      </div>

      <!-- Fila 3-->

      <!-- Linea de Formacion -->
      <div v-if="Matter" class="table-cell d-flex">
        <div class="primary-cell w-50">
          {{
            $getVisibleNames(
              "mesh.formationline",
              false,
              "Línea De Formación"
            ).toUpperCase()
          }}
        </div>
        <div class="secundary-cell secundary-table1 w-50">
          <template v-if="getFormationLine">
            <div>
              {{ getFormationLine.name }}
            </div>
          </template>
        </div>
      </div>
      <!-- N° Docentes -->
      <div class="table-cell d-flex">
        <div class="primary-cell w-50">N° DOCENTES</div>
        <div class="secundary-cell w-50">
          <span v-if="Matter && !Matter.use_matter_values">
            {{
              Matter.suggested_teachers == null
                ? "0"
                : Matter.suggested_teachers
            }}
          </span>
          <span v-else>
            {{
              oldMatter.suggested_teachers == null
                ? "0"
                : oldMatter.suggested_teachers
            }}
          </span>
        </div>
      </div>
      <!-- N° Estudiantes -->
      <div class="table-cell d-flex">
        <div class="primary-cell w-50">N° ESTUDIANTES</div>
        <div class="secundary-cell w-50">
          <span v-if="Matter && !Matter.use_matter_values">
            {{
              Matter.suggested_students == null
                ? "0"
                : Matter.suggested_students
            }}
          </span>
          <span v-else>
            {{
              oldMatter.suggested_students == null
                ? "0"
                : oldMatter.suggested_students
            }}
          </span>
        </div>
      </div>

      <!-- Fila 4 -->

      <!-- Área de Formación -->
      <div class="table-cell d-flex">
        <div class="primary-cell w-50">
          {{
            $getVisibleNames(
              "mesh.formationarea",
              false,
              "Área De Formación"
            ).toUpperCase()
          }}
        </div>
        <div class="secundary-cell secundary-table1 w-50">
          <template v-if="getFormationAreas">
            <div>
              {{ getFormationAreas.name }}
            </div>
          </template>
        </div>
      </div>
      <!-- Tipo de Asignatura -->
      <div class="table-cell d-flex">
        <div class="primary-cell w-50">
          {{
            $getVisibleNames(
              "mesh.mattertype",
              false,
              "Tipo De Asignatura"
            ).toUpperCase()
          }}
        </div>
        <div class="secundary-cell secundary-table1 w-50">
          <template v-if="getMatterType.length > 0">
            <span>
              {{ getMatterType.map((x) => x.name).join(", ") }}
            </span>
          </template>
          <template v-else>
            <div>N/A</div>
          </template>
        </div>
      </div>
      <!-- Régimen -->
      <div v-if="Matter" class="table-cell d-flex">
        <div class="primary-cell w-50">
          {{
            $getVisibleNames("mesh.periodtype", false, "Régimen").toUpperCase()
          }}
        </div>
        <div class="secundary-cell secundary-table1 w-50">
          <div v-if="getRegimen">
            {{ getRegimen.name }}
          </div>
        </div>
      </div>

      <!-- Fila 5 -->

      <!-- Total de Horas Pedagógicas UNAB Semanales-->
      <div
        class="table-cell d-flex"
        v-if="
          (institution && institution.internal_use_id == 'unab') ||
          $debug_change_unab
        "
      >
        <div class="primary-cell w-50">
          {{
            $getVisibleNames(
              "manual.total_pedagogical_hours",
              true,
              "Total de Horas Pedagógicas"
            ).toUpperCase()
          }}
          SEMANAL
        </div>
        <div class="secundary-cell w-50">
          <div v-if="Matter && !Matter.use_matter_values && totalPedagogical">
            <span v-if="Matter.weeks > 0">
              {{ parseFloat((totalPedagogical / Matter.weeks).toFixed(2)) }}
            </span>
            <span v-else>0</span>
          </div>
          <div v-else>
            <span v-if="oldMatter.weeks">
              {{ parseFloat((totalPedagogical / oldMatter.weeks).toFixed(2)) }}
            </span>
            <span v-else>0</span>
          </div>
        </div>
      </div>
      <!-- Total de Horas Pedagógicas (En Cronológicas) UNAB Semanales-->
      <div
        class="table-cell d-flex"
        v-if="
          (institution && institution.internal_use_id == 'unab') ||
          $debug_change_unab
        "
      >
        <div class="primary-cell w-50">
          {{
            $getVisibleNames(
              "manual.total_pedagogical_hours_to_chronological",
              true,
              "Total de Horas Pedagógicas (En Cronológicas)"
            ).toUpperCase()
          }}
          SEMANAL
        </div>
        <div class="secundary-cell w-50">
          <div
            v-if="
              Matter &&
              !Matter.use_matter_values &&
              profileType &&
              totalPedagogical
            "
          >
            <span v-if="Matter.weeks > 0">
              {{
                parseFloat(
                  (totalPedagogicalToChronological / Matter.weeks).toFixed(2)
                )
              }}
            </span>
            <span v-else>0</span>
          </div>
          <div v-else>
            <span v-if="oldMatter.weeks && profileType">
              {{
                parseFloat(
                  (totalPedagogicalToChronological / oldMatter.weeks).toFixed(2)
                )
              }}
            </span>
            <span v-else>0</span>
          </div>
        </div>
      </div>
      <!-- Total de Horas Cronológicas Semanales-->
      <div
        class="table-cell d-flex"
        v-if="
          (institution && institution.internal_use_id == 'unab') ||
          $debug_change_unab
        "
      >
        <div class="primary-cell w-50">
          {{
            $getVisibleNames(
              "manual.total_chronological_hours",
              true,
              "Total de Horas Cronológicas"
            ).toUpperCase()
          }}
          SEMANAL
        </div>
        <div class="secundary-cell w-50">
          <div
            v-if="
              Matter &&
              !Matter.use_matter_values &&
              profileType &&
              totalChronological
            "
          >
            <span v-if="Matter.weeks > 0">
              {{ parseFloat((totalChronological / Matter.weeks).toFixed(2)) }}
            </span>
            <span v-else>0</span>
          </div>
          <div v-else>
            <span v-if="oldMatter.weeks">
              {{
                parseFloat((totalChronological / oldMatter.weeks).toFixed(2))
              }}
            </span>
            <span v-else>0</span>
          </div>
        </div>
      </div>
      <!-- Total de Horas Pedagógicas UNAB-->
      <div
        class="table-cell d-flex"
        v-if="
          (institution && institution.internal_use_id == 'unab') ||
          $debug_change_unab
        "
      >
        <div class="primary-cell w-50">
          {{
            $getVisibleNames(
              "manual.total_pedagogical_hours",
              true,
              "Total de Horas Pedagógicas"
            ).toUpperCase()
          }}
        </div>
        <div class="secundary-cell w-50">
          <div v-if="totalPedagogical">
            {{ totalPedagogical }}
          </div>
        </div>
      </div>
      <!-- Total de Horas Pedagógicas (En Cronológicas) -->
      <div class="table-cell d-flex">
        <div class="primary-cell w-50">
          {{
            $getVisibleNames(
              "manual.total_pedagogical_hours",
              true,
              "Total de Horas Pedagógicas"
            ).toUpperCase()
          }}
        </div>
        <div class="secundary-cell w-50">
          <span v-if="totalPedagogical">{{ totalPedagogical }}</span>
          <!-- Se Comenta por que quieren visualizar solo las Horas Pedagogicas en vez de las Cronologicas (26-08-2024) -->
          <!-- <span v-if="profileType && totalPedagogical">
            {{ totalPedagogicalToChronological }}
          </span> -->
        </div>
      </div>
      <!-- Total de Horas Cronológicas -->
      <div class="table-cell d-flex">
        <div
          v-if="
            (institution && institution.internal_use_id == 'duoc_uc') ||
            $debug_change_duoc
          "
          class="primary-cell w-50"
        >
          HORAS DE TRABAJO AUTÓNOMO
        </div>
        <div v-else class="primary-cell w-50">
          {{
            $getVisibleNames(
              "manual.total_chronological_hours",
              true,
              "Total de Horas Cronológicas"
            ).toUpperCase()
          }}
        </div>
        <div class="secundary-cell w-50">
          {{ totalChronological }}
        </div>
      </div>
      <!-- Créditos SCT Totales -->
      <div class="table-cell d-flex">
        <div class="primary-cell w-50">
          {{
            $getVisibleNames(
              "manual.credits",
              true,
              "Créditos SCT Totales"
            ).toUpperCase()
          }}
        </div>
        <div class="secundary-cell w-50">
          <div v-if="Matter && !Matter.use_matter_values">
            <span>
              {{ Matter.credits }}
            </span>
          </div>
          <div v-else>
            <span>{{ oldMatter.credits }}</span>
          </div>
        </div>
      </div>
      <!-- Créditos Institucionales -->
      <div
        v-if="
          (institution &&
            (institution.internal_use_id == 'unab' ||
              institution.internal_use_id == 'duoc_uc')) ||
          $debug_change_unab ||
          $debug_change_duoc
        "
        class="table-cell d-flex"
      >
        <div class="primary-cell w-50">
          {{
            $getVisibleNames(
              "manual.institutional_credits",
              true,
              "Créditos Institucionales"
            ).toUpperCase()
          }}
        </div>
        <div class="secundary-cell w-50">
          <div v-if="Matter && !Matter.use_matter_values">
            <span>
              {{ Matter.institutional_credits }}
            </span>
          </div>
          <div v-else>
            <span>
              {{ oldMatter.institutional_credits }}
            </span>
          </div>
        </div>
      </div>
      <!-- Fila 6 -->

      <!-- Ambiente de Aprendizaje  -->
      <div
        v-if="institution && institution.show_study_environment_menu"
        class="table-cell d-flex"
      >
        <div class="primary-cell w-50">
          {{
            $getVisibleNames(
              "mesh.studyenvironment",
              false,
              "Ambiente de Aprendizaje"
            ).toUpperCase()
          }}
        </div>
        <div class="secundary-cell secundary-table1 w-50">
          <template v-if="getMatterStudyEnvironments.length > 0">
            <span>
              {{ getMatterStudyEnvironments.map((x) => x.name).join(", ") }}
            </span>
          </template>
          <template v-else>
            <div>N/A</div>
          </template>
        </div>
      </div>
      <!-- Modalidad de Asignatura -->
      <div class="table-cell d-flex">
        <div class="primary-cell w-50">
          {{
            $getVisibleNames(
              "mesh.modality",
              false,
              "Modalidad de Asignatura"
            ).toUpperCase()
          }}
        </div>
        <div class="secundary-cell secundary-table1 w-50">
          <template v-if="getModalities.length > 0">
            <span>
              {{ getModalities.map((x) => x.name).join(", ") }}
            </span>
          </template>
          <template v-else>
            <div>N/A</div>
          </template>
        </div>
      </div>
      <!-- Fecha de Publicación -->
      <div
        v-if="
          ((institution && institution.internal_use_id == 'duoc_uc') ||
            $debug_change_duoc) &&
          Matter &&
          Matter.use_matter_values &&
          old_matter
        "
        class="table-cell d-flex"
      >
        <div class="primary-cell w-50">FECHA DE PUBLICACIÓN</div>
        <div class="secundary-cell w-50">
          <div v-if="allows_crud && !old_matter.is_closed" class="w-100">
            <b-form-datepicker
              v-model="old_matter.publish_date"
              :disabled="old_matter.is_closed"
              :date-format-options="{
                day: '2-digit',
                month: '2-digit',
                year: 'numeric',
              }"
              @input="changePublishDate()"
              locale="es"
              size="sm"
            ></b-form-datepicker>
          </div>
          <div v-else class="ml-3">
            {{ old_matter.publish_date | FormatToDate }}
          </div>
        </div>
      </div>
      <!-- Estado del PMD  -->
      <div
        v-if="
          Matter &&
          ((institution && institution.internal_use_id == 'duoc_uc') ||
            $debug_change_duoc)
        "
        class="table-cell d-flex"
      >
        <div class="primary-cell w-50">ESTADO DEL PMD</div>
        <div class="secundary-cell-state w-50">
          <span
            :class="{
              'state-no-initiate':
                learningExperiences.length == 0 && allows_crud,
            }"
            v-if="
              learningExperiences.length == 0 &&
              (oldMatter.is_closed == false ||
                (Matter && Matter.is_closed == false))
            "
          >
            No Iniciado
          </span>
          <span
            :class="{
              'state-initiate': learningExperiences.length > 0 && allows_crud,
            }"
            v-else-if="
              learningExperiences.length > 0 &&
              (oldMatter.is_closed == false ||
                (Matter && Matter.is_closed == false))
            "
          >
            En Proceso
          </span>
          <span
            :class="{
              'state-closed':
                oldMatter.is_closed == true &&
                Matter &&
                Matter.is_closed &&
                allows_crud,
            }"
            v-else-if="
              oldMatter.is_closed == true && Matter && Matter.is_closed == true
            "
          >
            Finalizado
          </span>
        </div>
      </div>
    </div>
    <!-- Vista Desktop -->
    <div class="table2-container desktop-view" v-if="matterHours.length > 0">
      <div class="items-activity">
        <div class="column title-table2">TIPO DE ACTIVIDAD</div>
        <div
          class="column title-column"
          v-for="item in matterHours.filter(
            (x) => x.uses_module_minutes == true
          )"
          :key="'column1' + item.id"
        >
          {{ item.name.toUpperCase() }}
        </div>
        <div
          class="column title-column"
          v-for="item in matterHours.filter(
            (x) => x.uses_module_minutes == false
          )"
          :key="'column1.1' + item.id"
        >
          {{ item.name.toUpperCase() }}
        </div>
      </div>
      <div class="row2">
        <div class="column items-activity title-table2">
          <div>TOTAL DE HORAS</div>
        </div>
        <div
          class="column"
          v-for="item in matterHours.filter(
            (x) => x.uses_module_minutes == true
          )"
          :key="'column2' + item.id"
        >
          <div
            class="result-hours d-flex"
            :class="{
              'hours-div': item.counts_towards_credits,
              'hours-2-div': !item.counts_towards_credits,
            }"
          >
            <span
              v-b-tooltip.v-secondary.noninteractive="
                item.counts_towards_credits
                  ? `Se incluye en el ${$getVisibleNames(
                      'manual.total_pedagogical_hours',
                      true,
                      'Total de Horas Pedagógicas'
                    )} contables`
                  : `No se incluye en el ${$getVisibleNames(
                      'manual.total_pedagogical_hours',
                      true,
                      'Total de Horas Pedagógicas'
                    )} contables`
              "
            >
              {{ item.hours }}
            </span>
          </div>
        </div>
        <div
          class="column"
          v-for="item in matterHours.filter(
            (x) => x.uses_module_minutes == false
          )"
          :key="'column2.1' + item.id"
        >
          <div
            class="result-hours d-flex"
            :class="{
              'hours-div': item.counts_towards_credits,
              'hours-2-div': !item.counts_towards_credits,
            }"
          >
            <span
              v-b-tooltip.v-secondary.noninteractive="
                item.counts_towards_credits
                  ? `Se incluye en el ${$getVisibleNames(
                      'manual.total_chronological_hours',
                      true,
                      'Total de Horas Cronológicas'
                    )} contables`
                  : `No se incluye en el ${$getVisibleNames(
                      'manual.total_chronological_hours',
                      true,
                      'Total de Horas Cronológicas'
                    )} contables`
              "
            >
              {{ item.hours }}
            </span>
          </div>
        </div>
      </div>
      <div class="row3">
        <div class="column items-activity title-table2">
          <div>EXIGENCIA DE ASISTENCIA</div>
        </div>
        <div
          class="column"
          v-for="item in matterHours.filter(
            (x) => x.uses_module_minutes == true
          )"
          :key="'column3' + item.id"
        >
          <span
            v-if="
              item.attendance_requirement != '' &&
              item.attendance_requirement != null &&
              item.attendance_requirement != 0
            "
            >{{ item.attendance_requirement }}%
          </span>
          <span v-else> N/A </span>
        </div>
        <div
          class="column"
          v-for="item in matterHours.filter(
            (x) => x.uses_module_minutes == false
          )"
          :key="'column3.1' + item.id"
        >
          <span
            v-if="
              item.attendance_requirement != '' &&
              item.attendance_requirement != null &&
              item.attendance_requirement != 0
            "
            >{{ item.attendance_requirement }}%
          </span>
          <span v-else> N/A </span>
        </div>
      </div>
    </div>

    <!-- Vista Mobile -->
    <div class="table2-container mobile-view" v-if="matterHours.length > 0">
      <!-- Primera fila -->
      <div class="row">
        <div class="column title-mobile items-activity">TIPO DE ACTIVIDAD</div>
        <div class="column items-activity">TOTAL DE HORAS</div>
        <div class="column items-activity">EXIGENCIA DE ASISTENCIA</div>
      </div>

      <!-- Segunda Fila -->
      <div
        class="row"
        v-for="item in matterHours.filter((x) => x.uses_module_minutes == true)"
        :key="'row' + item.id"
      >
        <div class="column title-mobile items-activity">
          {{ item.name.toUpperCase() }}
        </div>
        <div class="column">
          <div
            class="result-hours d-flex"
            :class="{
              'hours-div': item.counts_towards_credits,
              'hours-2-div': !item.counts_towards_credits,
            }"
          >
            <span
              v-b-tooltip.v-secondary.noninteractive="
                item.counts_towards_credits
                  ? `Se incluye en el ${$getVisibleNames(
                      'manual.total_pedagogical_hours',
                      true,
                      'Total de Horas Pedagógicas'
                    )} contables`
                  : `No se incluye en el ${$getVisibleNames(
                      'manual.total_pedagogical_hours',
                      true,
                      'Total de Horas Pedagógicas'
                    )} contables`
              "
            >
              {{ item.hours }}
            </span>
          </div>
        </div>
        <div class="column">
          <span
            v-if="
              item.attendance_requirement != '' &&
              item.attendance_requirement != null &&
              item.attendance_requirement != 0
            "
            >{{ item.attendance_requirement }}%
          </span>
          <span v-else> N/A </span>
        </div>
      </div>
      <div
        class="row"
        v-for="item in matterHours.filter(
          (x) => x.uses_module_minutes == false
        )"
        :key="'row1' + item.id"
      >
        <div class="column title-mobile items-activity">
          {{ item.name.toUpperCase() }}
        </div>
        <div class="column">
          <div
            class="result-hours d-flex"
            :class="{
              'hours-div': item.counts_towards_credits,
              'hours-2-div': !item.counts_towards_credits,
            }"
          >
            <span
              v-b-tooltip.v-secondary.noninteractive="
                item.counts_towards_credits
                  ? `Se incluye en el ${$getVisibleNames(
                      'manual.total_chronological_hours',
                      true,
                      'Total de Horas Cronológicas'
                    )} contables`
                  : `No se incluye en el ${$getVisibleNames(
                      'manual.total_chronological_hours',
                      true,
                      'Total de Horas Cronológicas'
                    )} contables`
              "
            >
              {{ item.hours }}
            </span>
          </div>
        </div>
        <div class="column">
          <span
            v-if="
              item.attendance_requirement != '' &&
              item.attendance_requirement != null &&
              item.attendance_requirement != 0
            "
            >{{ item.attendance_requirement }}%
          </span>
          <span v-else> N/A </span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import * as names from "@/store/names";
import { mapGetters } from "vuex";
import { toast } from "@/utils/utils";

export default {
  name: "NewMatterPresentation",
  components: {
    PrerequisitesPopover: () =>
      import("@/components/reusable/PrerequisitesPopover"),
  },
  props: {
    oldMatter: {
      type: Object,
      required: true,
    },
    Matter: {
      type: [Object, null],
      required: false,
    },
    allows_crud: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      old_matter: { ...this.oldMatter },
      default_bg_color: "#fff",
    };
  },
  computed: {
    ...mapGetters({
      egress_profiles_matters: names.EGRESS_PROFILES_MATTERS,
      time_allocations: names.TIME_ALLOCATIONS,
      ep_matter_time_allocations: names.EP_MATTER_TIME_ALLOCATIONS,
      matter_time_allocations: names.MATTER_TIME_ALLOCATIONS,
      studyEnvironmentsTimeAllocation: names.STUDY_ENVIRONMENTS_TIME_ALLOCATION,
      matters: names.MATTERS,
      user: "getUser",
      formationAreas: names.FORMATION_AREAS,
      formationLines: names.FORMATION_LINES,
      matterTypes: names.MATTER_TYPES,
      faculties: names.FACULTIES,
      matterContributions: names.MATTER_CONTRIBUTIONS,
      modalities: names.MODALITIES,
      egress_profiles: names.EGRESS_PROFILES,
      profile_types: names.PROFILE_TYPES,
      studyEnvironments: names.STUDY_ENVIRONMENTS,
      regimes: names.REGIMES,
      career: names.CAREERS,
      institution: "getInstitution",
      learningExperiences: names.LEARNING_EXPERIENCES,
    }),
    faculty() {
      if (!this.careerFilter) return null;
      return this.faculties.find((x) => x.id == this.careerFilter.faculty);
    },
    careerFilter() {
      if (!this.egressProfile) return null;
      return this.career.find((x) => x.id == this.egressProfile.career);
    },
    egressProfile() {
      if (this.Matter && !this.Matter.egress_profile) return [];
      return this.egress_profiles.find(
        (x) => x.id == this.Matter.egress_profile
      );
    },
    getFormationLine() {
      let formation_line = { name: "N/A" };
      if (!this.Matter) return formation_line;
      return (
        this.formationLines.find((x) => this.Matter.formation_line == x.id) ||
        formation_line
      );
    },
    timeAllocationsIds() {
      if (this.Matter && !this.Matter.use_matter_values) {
        if (this.Matter.study_environments.length == 0)
          return this.time_allocations.map((x) => x.id);
        return [
          ...new Set(
            this.studyEnvironmentsTimeAllocation
              .filter((x) =>
                this.Matter.study_environments.includes(x.study_environment)
              )
              .map((x) => x.time_allocation)
          ),
        ];
      } else {
        if (this.oldMatter.study_environments.length == 0)
          return this.time_allocations.map((x) => x.id);
        return [
          ...new Set(
            this.studyEnvironmentsTimeAllocation
              .filter((x) =>
                this.oldMatter.study_environments.includes(x.study_environment)
              )
              .map((x) => x.time_allocation)
          ),
        ];
      }
    },
    getFormationAreas() {
      let formation_area = {
        name: "N/A",
      };
      if (!this.Matter) return formation_area;
      return (
        this.formationAreas.find((x) => x.id == this.Matter.formation_area) ||
        formation_area
      );
    },
    getMatterType() {
      if (!this.Matter && !this.oldMatter) return [];
      let matter_types_ids = [];
      if (this.Matter && !this.Matter.use_matter_values)
        matter_types_ids = this.Matter.matter_types;
      else matter_types_ids = this.oldMatter.matter_types;
      return this.matterTypes.filter((x) => matter_types_ids.includes(x.id));
    },
    getEgressProfile() {
      const instance = this.egress_profiles.find(
        (x) => x.id == this.Matter.egress_profile
      );
      let arr = [];
      if (instance) {
        if (instance.code.trim() != "") arr.push(instance.code);
        if (instance.init_date) arr.push(instance.init_date);
        if (instance.professional_title.trim() != "")
          arr.push(instance.professional_title);
        return arr.join(" - ");
      } else return null;
    },
    getRegimen() {
      if (!this.Matter) return [];
      const get_regime = this.regimes.find((x) => x.id == this.Matter.regime);
      return get_regime ? get_regime : { name: "N/A" };
    },
    getModalities() {
      if (!this.Matter && !this.oldMatter) return [];
      let modalities_ids = [];
      if (this.Matter && !this.Matter.use_matter_values)
        modalities_ids = this.Matter.modalities;
      else modalities_ids = this.oldMatter.modalities;
      return this.modalities.filter((x) => modalities_ids.includes(x.id));
    },
    getMatterStudyEnvironments() {
      if (!this.Matter && !this.oldMatter) return [];
      let study_environments_ids = [];
      if (this.Matter && !this.Matter.use_matter_values)
        study_environments_ids = this.Matter.study_environments;
      else study_environments_ids = this.oldMatter.study_environments;
      return this.studyEnvironments.filter((x) =>
        study_environments_ids.includes(x.id)
      );
    },
    matterFormationLine() {
      if (!this.Matter) return this.default_bg_color;
      const formation_line = this.formationLines.find(
        (x) =>
          x.id == this.Matter.formation_line &&
          this.careerFilter &&
          x.faculties.includes(this.careerFilter.faculty)
      );
      if (formation_line) return formation_line.color;
      else return this.default_bg_color;
    },
    prerequisites() {
      let results = [];
      if (!this.Matter) return results;
      this.Matter.prerequisites.forEach((element) => {
        let egress_profile_matter = this.egress_profiles_matters.find(
          (x) => x.id == element
        );
        if (egress_profile_matter) {
          let instance = this.matters.find(
            (x) => x.id == egress_profile_matter.matter
          );
          if (instance) {
            let obj = {
              name: instance.name,
              code: instance.code,
              order: egress_profile_matter.order,
            };
            results.push(obj);
          }
          return results.sort(function (a, b) {
            if (a.order > b.order) return 1;
            if (a.order < b.order) return -1;

            return 0;
          });
        }
      });
      return results;
    },
    prerequisitesNames() {
      let list = [];
      this.Matter.prerequisites.forEach((element) => {
        let egress_profile_matter = this.egress_profiles_matters.find(
          (x) => x.id == element
        );
        if (egress_profile_matter) {
          let instance = this.matters.find(
            (x) => x.id == egress_profile_matter.matter
          );

          if (instance) {
            instance["formation_line"] = egress_profile_matter.formation_line;
            instance["order"] = egress_profile_matter.order;
            list.push(instance);
          }
        }
      });
      return list.sort(function (a, b) {
        if (a.order > b.order) return 1;
        if (a.order < b.order) return -1;

        return 0;
      });
    },
    totalPedagogical() {
      let count = 0;
      this.matterHours
        .filter((x) => x.uses_module_minutes == true)
        .forEach((element) => {
          if (
            element.counts_towards_credits &&
            element.hours &&
            element.hours >= 0
          ) {
            count += parseFloat(element.hours);
          }
        });
      return count;
    },
    totalPedagogicalToChronological() {
      if (!this.profileType) return 0;
      if (
        this.institution &&
        parseInt(this.institution.hour_decimal_places) === 0
      ) {
        return parseFloat(
          Math.round(
            this.totalPedagogical *
              (parseFloat(this.profileType.module_minutes) / 60)
          )
        );
      } else
        return parseFloat(
          (
            this.totalPedagogical *
            (parseFloat(this.profileType.module_minutes) / 60)
          ).toFixed(
            this.institution &&
              parseInt(this.institution.hour_decimal_places) > 0
              ? parseInt(this.institution.hour_decimal_places)
              : 2
          )
        );
    },
    totalChronological() {
      let count = 0;
      this.matterHours
        .filter((x) => x.uses_module_minutes == false)
        .forEach((element) => {
          if (
            element.counts_towards_credits &&
            element.hours &&
            element.hours >= 0
          ) {
            count += parseFloat(element.hours);
          }
        });
      return count;
    },
    // totalHours() {
    //   if (!this.profileType) return 0;
    //   const total_hours =
    //     this.totalPedagogical * (this.profileType.module_minutes / 60) +
    //     this.totalChronological;
    //   return total_hours ? total_hours : 0;
    // },
    profileType() {
      if (!this.Matter && this.oldMatter) {
        if (this.institution && this.institution.module_minutes > 0)
          return { module_minutes: this.institution.module_minutes };
        else return { module_minutes: 60 };
      } else {
        if (!this.egressProfile) return { module_minutes: 60 };
        return this.profile_types.find(
          (x) => x.id == this.egressProfile.egress_profile_type
        );
      }
    },
    matterHours() {
      let list = [];
      this.time_allocations.forEach((element) => {
        if (this.Matter && !this.Matter.use_matter_values) {
          const matter_hour = this.ep_matter_time_allocations.find(
            (x) =>
              x.egress_profile_matter == this.Matter.id &&
              x.time_allocation == element.id
          );
          if (
            matter_hour &&
            (this.timeAllocationsIds.includes(matter_hour.time_allocation) ||
              !element.has_study_environments ||
              matter_hour.hours > 0 ||
              matter_hour.tem_hours > 0)
          )
            list.push({
              id: matter_hour.id,
              time_allocation: matter_hour.time_allocation,
              name: element.name,
              order: element.order,
              uses_module_minutes: element.uses_module_minutes,
              has_study_environments: element.has_study_environments,
              egress_profile_matter: matter_hour.egress_profile_matter,
              counts_towards_credits: matter_hour.counts_towards_credits,
              attendance_requirement: matter_hour.attendance_requirement,
              hours: matter_hour.hours,
              tem_hours:
                parseFloat(this.Matter.weeks) > 0
                  ? parseFloat(
                      (
                        parseFloat(matter_hour.hours) /
                        parseFloat(this.Matter.weeks)
                      ).toFixed(2)
                    )
                  : 0,
            });
        } else {
          const matter_hour = this.matter_time_allocations.find(
            (x) =>
              x.matter == this.oldMatter.id && x.time_allocation == element.id
          );
          if (
            matter_hour &&
            this.oldMatter &&
            (this.timeAllocationsIds.includes(matter_hour.time_allocation) ||
              !element.has_study_environments ||
              matter_hour.hours > 0 ||
              matter_hour.tem_hours > 0)
          )
            list.push({
              id: matter_hour.id,
              time_allocation: matter_hour.time_allocation,
              name: element.name,
              order: element.order,
              uses_module_minutes: element.uses_module_minutes,
              has_study_environments: element.has_study_environments,
              matter: matter_hour.matter,
              counts_towards_credits: matter_hour.counts_towards_credits,
              attendance_requirement: matter_hour.attendance_requirement,
              hours: matter_hour.hours,
              tem_hours:
                parseFloat(this.oldMatter.weeks) > 0
                  ? parseFloat(
                      (
                        parseFloat(matter_hour.hours) /
                        parseFloat(this.oldMatter.weeks)
                      ).toFixed(2)
                    )
                  : 0,
            });
        }
      });
      return list;
    },
  },
  methods: {
    changePublishDate() {
      if (this.old_matter) {
        this.patchOldMatter({ publish_date: this.old_matter.publish_date });
        toast("Fecha de Publicación Actualizada");
      }
    },
    patchOldMatter(item) {
      if (this.old_matter)
        this.$store
          .dispatch(names.PATCH_MATTER, {
            matter_id: this.old_matter.id,
            item: item,
          })
          .then((response) => {
            this.old_matter = response;
          });
    },
  },
  watch: {},
  mounted() {},
  created() {
    if (this.Matter && !isNaN(this.Matter.id)) {
      this.$store.dispatch(names.FETCH_EP_MATTER_TIME_ALLOCATIONS, {
        egress_profile_matter_id: this.Matter.id,
      });
      this.Matter.prerequisites.forEach((prerequisite_id) => {
        if (!this.matters.find((x) => x.id == prerequisite_id))
          this.$store
            .dispatch(names.FETCH_EGRESS_PROFILE_MATTER, prerequisite_id)
            .then((response) => {
              this.$store.dispatch(names.FETCH_MATTER, response.matter);
            });
      });
      this.$store
        .dispatch(names.FETCH_EGRESS_PROFILE, this.Matter.egress_profile)
        .then((response) => {
          this.$store
            .dispatch(names.FETCH_CAREER, response.career)
            .then((career) => {
              this.$store.dispatch(names.FETCH_FACULTY, career.faculty);
            });
        });
    }
    if (this.oldMatter) {
      this.$store.dispatch(names.FETCH_MATTER_TIME_ALLOCATIONS, {
        matter_id: this.oldMatter.id,
      });
    }
    if (this.Matter)
      this.$store.dispatch(
        names.FETCH_LEARNING_EXPERIENCES,
        this.Matter.matter
      );
    this.$store.dispatch(names.FETCH_PROFILE_TYPES);
    this.$store.dispatch(names.FETCH_STUDY_ENVIRONMENTS);
    this.$store.dispatch(names.FETCH_FORMATION_LINES);
    this.$store.dispatch(names.FETCH_FORMATION_AREAS);
    this.$store.dispatch(names.FETCH_MATTER_CONTRIBUTIONS);
    this.$store.dispatch(names.FETCH_MATTER_TYPES);
    this.$store.dispatch(names.FETCH_MODALITIES);
  },
};
</script>

<style scoped>
.campus-div {
  background-color: var(--primary-color);
  border: 1px solid #949292;
  color: white;
  width: auto;
  border-radius: 3px;
  margin: 0.1em 0.1em;
  min-width: 23px;
  max-width: 23px;
  padding: 1px 5px;
  flex-grow: 1;
  font-size: var(--thirth-font-size);
  text-align: center;
}
.campus-container {
  justify-content: center;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}
.state-no-initiate {
  color: red;
  font-weight: bold;
}
.state-initiate {
  color: rgba(255, 115, 0, 0.726);
  font-weight: bold;
}
.state-closed {
  color: green;
  font-weight: bold;
}
.title-presentation {
  display: flex;
  margin-bottom: 20px;
  margin-top: 20px;
  font-weight: bold;
}
.name-matter,
.code-matter {
  font-weight: bold;
}

.name-matter {
  color: white;
  background-color: var(--kl-fourth-color) !important;
}

.table-container {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  font-size: 10pt;
  margin-bottom: 20px;
  gap: 0px;
}

.table-cell {
  border: 1px solid rgba(0, 0, 0, 0.5);
}

.primary-cell {
  height: 100%;
  display: flex;
  padding-left: 1em;
  padding-right: 1em;
  align-items: center;
  background-color: var(--kl-menu-color) !important;
  color: white;
  font-weight: bold;
  text-align: start;
}

.secundary-cell {
  padding: 4px;
  text-align: center;
  margin-top: auto;
  margin-bottom: auto;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  /* font-weight: 500; */
}
.secundary-cell-state {
  color: black !important;
  padding: 4px;
  margin: 0px !important;
  text-align: center;
  margin-top: auto;
  margin-bottom: auto;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
}
.prerequisites-p {
  vertical-align: middle;
  margin: 7px 10px;
}

.secundary-table1 {
  justify-content: center;
}
.prerequisite-order {
  display: inline;
}
.prerequisite-code {
  display: none;
}
/* .campus-div {
  background-color: var(--primary-color);
  color: white;
  max-width: fit-content;
  border-radius: 3px;
  margin: 0.1em 0.1em;
  padding: 1px 4px;
  font-size: var(--thirth-font-size);
  text-align: center;
} */

@media (max-width: 1100px) {
  .table-container {
    grid-template-columns: repeat(2, 1fr);
  }
}
@media (max-width: 750px) {
  .table-container {
    grid-template-columns: repeat(1, 1fr);
  }
  .title-presentation {
    flex-direction: column;
    align-items: center;
  }

  .school-title,
  .program-title {
    width: 100%;
    text-align: center;
  }
  .school-title {
    margin-bottom: 1rem;
  }
}

.hours-div {
  background-color: var(--secondary-color);
  color: white;
  max-width: fit-content;
  line-height: 1;
  padding: 2px 4px;
  border-radius: 3px;
  font-size: var(--secondary-font-size);
  /* text-align: center; */
}
.hours-2-div {
  background-color: #20687e;
  color: white;
  max-width: fit-content;
  line-height: 1;
  padding: 2px 4px;
  border-radius: 3px;
  font-size: var(--secondary-font-size);
  /* text-align: center; */
}
/* Tabla 2 */

.table2-container {
  display: table;
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 2em;
  font-size: 10pt;
}

.items-activity {
  background-color: var(--kl-menu-color) !important;
  display: table-row;
  color: white !important;
  font-weight: bold;
}

.title-table2 {
  text-align: start !important;
  padding-left: 1em !important;
  padding-right: 1em !important;
  color: white !important;
  vertical-align: middle;
}

.row,
.row2,
.row3 {
  display: table-row;
}

.mobile-view {
  display: none;
}

.result-hours {
  margin-left: auto;
  margin-right: auto;
}
.column {
  display: table-cell;
  padding: 4px;
  border: 1px solid rgba(0, 0, 0, 0.5);
  color: black;
  vertical-align: middle;
}

.title-column {
  color: white !important;
  vertical-align: middle;
}

@media screen and (max-width: 800px) {
  .desktop-view {
    display: none;
  }
  .mobile-view {
    display: table;
  }
  .title-mobile {
    text-align: start !important;
    padding-left: 2em !important;
  }
}
@media print {
  .table-container {
    grid-template-columns: repeat(2, 1fr) !important;
  }
  .prerequisite-order {
    display: none;
  }
  .prerequisite-code {
    display: inline;
  }
}
</style>
